import React, { useEffect } from "react";
import { Routes as RouterRoutes, Route, Navigate } from "react-router-dom";

import { setNotificationToken } from "store/slices/authSlice";
import { setPreview } from "store/slices/articlePreviewSlice";
import { getToken } from "firebase/messaging";
import { messaging } from "auth/FirebaseAuth";

import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AUTHENTICATED_ENTRY } from "configs/AppConfig";
import { protectedRoutes, publicRoutes } from "configs/RoutesConfig";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import AppRoute from "./AppRoute";
import FirebaseAuthService from "services/FirebaseUserService";
import { HAS_PREVIEW_REQUIRED_KEYS } from "constants/NotificationConstant";

const { REACT_APP_VAPID_KEY } = process.env;

const Routes = () => {
  const { token } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(`requestPermission check`);
    if (token) {
      async function requestPermission() {
        try {
          const permission = await Notification.requestPermission();
          // console.log(`permission`, permission);
          if (permission === "granted") {
            const token = await getToken(messaging, {
              vapidKey: REACT_APP_VAPID_KEY,
            });

            console.log(`push notification token:`, Boolean(token));
            dispatch(setNotificationToken(token));
            const fcmTokenInBase = await FirebaseAuthService.saveUserFCMToken(
              token
            );
            console.log(`token is saved:`, fcmTokenInBase);
            // We can send the token to the server
          } else if (permission === "denied") {
            // Notifications are blocked
            alert(
              "Notifications are currently disabled, which may affect the functionality of the application. To enable notifications and ensure full functionality:\n\n1. Open your browser's settings or preferences.\n2. Look for 'Notifications' or 'Site settings'.\n3. Find the option to manage notifications for websites.\n4. Enable notifications or set the option to allow websites to send notifications.\n\nPlease note that enabling notifications is necessary for the application to function properly."
              // "Notifications are currently disabled, which may affect the functionality of the application. To enable notifications and ensure full functionality:\n\n1. Open your browser's settings or preferences.\n2. Look for 'Notifications' or 'Site settings'.\n3. Find the option to manage notifications for websites.\n4. Enable notifications or set the option to allow websites to send notifications.\n\nPlease note that enabling notifications is necessary for the application to function properly.\n\nFor instructions specific to your browser:\n- [Chrome](https://support.google.com/chrome/answer/3220216?hl=en&co=GENIE.Platform%3DDesktop)\n- [Firefox](https://support.mozilla.org/en-US/kb/push-notifications-firefox)\n- [Safari](https://www.pushengage.com/safari-push-notifications/)"
            );
          }
        } catch (error) {
          console.log(`error`, error);
        }
      }
      requestPermission();
    }
  }, [dispatch, token]);

  useEffect(() => {
    const handler = event => {
      const payload = event?.data;
      //  console.log(`payload`, payload);
      //  console.log(`title`, payload?.notification?.title);

      if (
        (payload?.notification?.title === "error" ||
          payload?.notification?.title === "success") &&
        Boolean(payload?.notification?.body)
      ) {
        // console.log(`сработало`);
        const uniqueErrorKey = new Date().getTime();
        message[payload.notification.title]({
          content: payload.notification.body,
          // duration: 5,
          key: uniqueErrorKey,
          onClick: () => message.destroy(uniqueErrorKey),
        });
      }
      if (Boolean(payload?.data?.data)) {
        const result = JSON.parse(event.data.data?.data);

        const isEmptyObject = Object.keys(result).length === 0;

        const hasRequiredKeys = HAS_PREVIEW_REQUIRED_KEYS.every(
          key => key in result
        );

        if (!isEmptyObject && hasRequiredKeys) {
          // console.log(`payload from article preview`, result);
          dispatch(setPreview(result));
        }
      }
    };

    navigator.serviceWorker.addEventListener("message", handler);

    return () => {
      navigator.serviceWorker.removeEventListener("message", handler);
    };
  }, [dispatch]);

  return (
    <RouterRoutes>
      <Route path="/" element={<ProtectedRoute />}>
        <Route
          path="/"
          element={<Navigate replace to={AUTHENTICATED_ENTRY} />}
        />
        {protectedRoutes.map((route, index) => {
          return (
            <Route
              key={route.key + index}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          );
        })}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
      <Route path="/" element={<PublicRoute />}>
        {publicRoutes.map(route => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          );
        })}
      </Route>
    </RouterRoutes>
  );
};

export default Routes;
