import {
  collection,
  addDoc,
  updateDoc,
  doc,
  getDocs,
  getDoc,
  query,
  orderBy,
  deleteDoc,
} from "firebase/firestore";

import FirebaseLicenseService from "./FirebaseLicenseService";
import { db, auth } from "auth/FirebaseAuth";
import { AUTH_USER_DATA } from "constants/AuthConstant";

const FirebaseMyProjectsService = {};

FirebaseMyProjectsService.fetchProjects = async () => {
  try {
    const { owner_uid } = JSON.parse(localStorage.getItem(AUTH_USER_DATA));

    const projectsDataCollectionRef = collection(
      db,
      `users/${owner_uid}/projectsData`
    );

    const querySnapshot = await getDocs(
      query(
        projectsDataCollectionRef,
        orderBy("dateCreated", "desc") // Sort by creation date in descending order
      )
    );

    const projectsData = [];
    querySnapshot.forEach(doc => {
      const data = doc.data();
      projectsData.push(data);
    });
    return projectsData;
  } catch (error) {
    throw error;
  }
};

FirebaseMyProjectsService.getProjectById = async projectId => {
  try {
    const { owner_uid } = JSON.parse(localStorage.getItem(AUTH_USER_DATA));
    const projectDataDocRef = doc(
      db,
      `users/${owner_uid}/projectsData`,
      projectId
    );
    const projectDataSnapshot = await getDoc(projectDataDocRef);

    if (projectDataSnapshot.exists()) {
      const projectData = projectDataSnapshot.data();
      return projectData;
    } else {
      return null;
    }
  } catch (error) {
    throw error;
  }
};

FirebaseMyProjectsService.addProject = async data => {
  try {
    const userUid = auth.currentUser.uid;

    const { projectsLimitIsExceeded, maxprojects } =
      await FirebaseLicenseService.projectsLimitCheck();

    if (projectsLimitIsExceeded && data.active) {
      throw new Error(
        `Active project limit exceeded. For your subscription, the active project limit - ${maxprojects} .`
      );
    }

    const projectsDataCollectionRef = collection(
      db,
      `users/${userUid}/projectsData`
    );
    const currentDate = new Date();

    const newData = {
      ...data,
      socialNetworks: data.socialNetworks ? data.socialNetworks : [],
      dateCreated: currentDate,
      dateUpdated: currentDate,
    };
    const projectDataRef = await addDoc(projectsDataCollectionRef, newData);
    const projectId = projectDataRef.id;

    await updateDoc(projectDataRef, {
      projectId: projectId,
    });

    return projectDataRef;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

FirebaseMyProjectsService.editProject = async data => {
  try {
    const userUid = auth.currentUser.uid;
    const projectId = data.projectId;

    const { projectsLimitIsExceeded, maxprojects, projectsData } =
      await FirebaseLicenseService.projectsLimitCheck();

    const currentProjectIsInActiveList = projectsData.find(
      p => p.projectId === data.projectId
    );
    if (
      projectsLimitIsExceeded &&
      data.active &&
      !currentProjectIsInActiveList
    ) {
      throw new Error(
        `Active project limit exceeded. For your subscription, the active project limit - ${maxprojects} .`
      );
    }

    const projectsDataCollectionRef = doc(
      db,
      `users/${userUid}/projectsData/${projectId}`
    );

    const newData = {
      ...data,
      dateUpdated: new Date(),
    };

    const projectDataRef = await updateDoc(projectsDataCollectionRef, newData);
    return projectDataRef;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

FirebaseMyProjectsService.updateProjectSiteData = async (
  projectData,
  siteData
) => {
  try {
    const userUid = auth.currentUser.uid;
    const projectId = projectData.projectId;

    const projectsDataCollectionRef = doc(
      db,
      `users/${userUid}/projectsData/${projectId}`
    );

    const newData = {
      site: siteData,
      dateUpdated: new Date(),
    };

    await updateDoc(projectsDataCollectionRef, newData);

    return newData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

FirebaseMyProjectsService.updateProjectSocialData = async (
  projectData,
  data,
  mode
) => {
  const projectId = projectData.projectId;
  try {
    const userUid = auth.currentUser.uid;

    const projectsDataCollectionRef = doc(
      db,
      `users/${userUid}/projectsData/${projectId}`
    );

    const projectDoc = await getDoc(projectsDataCollectionRef);
    if (!projectDoc.exists()) {
      throw new Error("Project not found");
    }
    const projectData = projectDoc.data();
    const socialData = projectData.socialData || [];

    let updatedSocialData;

    switch (mode) {
      case "save":
        updatedSocialData = [...socialData, data];
        break;

      case "update":
        updatedSocialData = socialData.map(social =>
          social.type === data.type ? { ...social, ...data } : social
        );
        break;

      case "delete":
        updatedSocialData = socialData.filter(
          social => social.type !== data.type
        );
        break;

      default:
        throw new Error("Invalid mode");
    }

    const newData = {
      socialData: updatedSocialData,
      dateUpdated: new Date(),
    };

    await updateDoc(projectsDataCollectionRef, newData);

    return newData;
  } catch (error) {
    console.error("Error in updating social data:", error);
    throw error;
  }
};

FirebaseMyProjectsService.updateProjectSubjectData = async (
  projectData,
  data,
  mode
) => {
  const projectId = projectData.projectId;
  try {
    const userUid = auth.currentUser.uid;

    const projectsDataCollectionRef = doc(
      db,
      `users/${userUid}/projectsData/${projectId}`
    );

    const projectDoc = await getDoc(projectsDataCollectionRef);
    if (!projectDoc.exists()) {
      throw new Error("Project not found");
    }
    const projectData = projectDoc.data();
    const projectContentGenerationData =
      projectData.projectContentGenerationData || [];

    let updatedSubjectData;

    switch (mode) {
      case "save":
        updatedSubjectData = [...projectContentGenerationData, data];
        break;

      case "update":
        updatedSubjectData = projectContentGenerationData.map(subject =>
          subject.id === data.id ? { ...subject, ...data } : subject
        );
        break;

      case "delete":
        updatedSubjectData = projectContentGenerationData.filter(
          subject => subject.id !== data.id
        );
        break;

      default:
        throw new Error("Invalid mode");
    }

    const newData = {
      projectContentGenerationData: updatedSubjectData,
      dateUpdated: new Date(),
    };

    await updateDoc(projectsDataCollectionRef, newData);

    return newData;
  } catch (error) {
    console.error("Error in updating subject data:", error);
    throw error;
  }
};

FirebaseMyProjectsService.deleteProject = async projectId => {
  try {
    const userUid = auth.currentUser.uid;

    const projectDataDocRef = doc(
      db,
      `users/${userUid}/projectsData`,
      projectId
    );

    await deleteDoc(projectDataDocRef);
    return projectId;
  } catch (error) {
    throw error;
  }
};

export default FirebaseMyProjectsService;
